import { OrganisationBaseType } from './organisations.type';

export enum AdminRole {
  ADMIN = 'admin',
  COMPOBOT_MANAGER = 'compobot_manager',
  CHALLENGE_MANAGER = 'challenge_manager',
  USER_MANAGER = 'user_manager',
}

export type AdminType = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  roles: AdminRole[];
  organisation: OrganisationBaseType;
};
