import { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Button,
  Box,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField, Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompobotTelemetry, getCompobots, getCompobotKernelLogs } from '../../services/compobot.service';
import { CompobotTelemetryType, CompobotType, CompobotKernelLogType } from '../../types/compobots.type';
import { lowerCase } from 'lodash';
import LineChart from '../../components/Chart/LineChart';
import BarChart from '../../components/Chart/BarChart';
import Tile from '../../components/Tile/Tile';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function CompoBotTelemetryPage() {// const [fvNev, fvSettere] = updateloReactFv<tipusa>(tipusAmitFelulirAKonkretTipusa)
  const { t } = useTranslation();
  const [telemetryData, setTelemetryData] = useState<CompobotTelemetryType[]>([]);
  const [logs, setLogs] = useState<CompobotKernelLogType[]>([]);
  const [compobots, setCompobots] = useState<CompobotType[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [logLevel, setLogLevel] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  //--------filter initialization, declaration--------
  useEffect(() => {
    getCompobots().then((r) => setCompobots(r.data));
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get('deviceId') || '';
    const fromDateParam = searchParams.get('fromDate') || '';
    const logLevelParam = searchParams.get('logLevel') || '';
    setSelectedDeviceId(deviceIdParam);
    setFromDate(fromDateParam);
    setLogLevel(logLevelParam);
    handleFetchTelemetry(deviceIdParam, fromDateParam);
    handleFetchLogs(deviceIdParam, fromDateParam, logLevelParam);
  }, [location.search]);

  const handleFetchTelemetry = async (deviceId?: string, fromDate?: string) => {
    try {
      const fetchedTelemetry = await getCompobotTelemetry(deviceId, fromDate);
      setTelemetryData(fetchedTelemetry.data);
    } catch (error) {
      console.error('Error fetching telemetry: ', error);
    }
  };

  const handleFetchLogs = async (deviceId?: string, fromDate?: string, logLevel?: string) => {
    try {
      const fetchedLogs = await getCompobotKernelLogs(deviceId, fromDate, logLevel);
      setLogs(fetchedLogs.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const updateSearchParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    navigate({ search: searchParams.toString() });
  };

  const clearFilters = () => {
    setSelectedDeviceId('');
    setFromDate('');
    setLogLevel('');
    updateSearchParams({ deviceId: '', fromDate: '', logLevel: ''  });
    handleFetchTelemetry();
    handleFetchLogs();
  };

  //------tile and chart datas---------

  const testData = [444, 123, 999];

  const tileLogData = (type_ : string) => {
    return logs.filter(item => item.key === type_)
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1)).map(item => item.value);
  };

  const tileTelemetryData = (type_ : string) => {
    return telemetryData.filter(item => item.key === type_)
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1)).map(item => item.value);
  };

  const chartTelemetryData = (type_ : string) => { 
    return telemetryData.filter(item => lowerCase(item.key) === lowerCase(type_))
      .sort((one, other) => (one.createdAt > other.createdAt ? 1 : -1));
  };

  //---------UI return elements-----------

  return (
    <Container>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="a"
          onClick={() => navigate('/compobots')}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t('compobotTelemetryPage.compobotList')}
        </Typography>
        <Typography variant="h4" component="span">
          {'>'}
        </Typography>
        <Typography variant="h4" component="span">
          {t('compobotTelemetryPage.telemetry')}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="compobot-select-label">{t('compobotTelemetryPage.deviceId')}</InputLabel>
          <Select
            labelId="compobot-select-label"
            value={selectedDeviceId || 'all'}
            label={t('compobotTelemetryPage.deviceId')}
            onChange={(e) => {
              const value = e.target.value === 'all' ? '' : e.target.value;
              setSelectedDeviceId(value);
              updateSearchParams({ deviceId: value });
              handleFetchTelemetry(value, fromDate);
              handleFetchLogs(value, fromDate, logLevel);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {compobots.map((compobot) => (
              <MenuItem key={compobot.deviceId} value={compobot.deviceId}>
                {compobot.deviceId} - {compobot.organisation?.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t('compobotTelemetryPage.fromDate')}
          type="date"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ fromDate: e.target.value });
            handleFetchTelemetry(selectedDeviceId, e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" onClick={clearFilters}>
          {t('compobotTelemetryPage.clearFilters')}
        </Button>
      </Stack>
      
      <Paper sx={{ p: 6, mb: 1 }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 2, mb: 10 }}>
          <Tile label={'Last Refresh'} value={testData} />
          <Tile label={'Compobot Status'} value={tileLogData('status')} />
          <Tile label={'Door opening counter'} value={tileTelemetryData('dooropeningcount')} />
          <Tile label={'CPU Temperature'} value={tileLogData('CPUtemp')} />
          <Tile label={'CPU %'} value={tileLogData('CPUusage')} />
          <Tile label={'Memory %'} value={tileLogData('CPUtemp')} />
          
          <Tile label={'Weight (current total)'} value={tileTelemetryData('weight')} />
          <Tile label={'Weight (last delta)'} value={tileTelemetryData('deltaweight')} />
          <Tile label={'Internet Ping (ms)'} value={tileLogData('netPing')} />
          <Tile label={'Internet Upload Speed'} value={tileLogData('netUp')} />
          <Tile label={'Internet Download Speed'} value={tileLogData('netDown')} />
          <Tile label={'Last Point'} value={tileTelemetryData('lastPoint')} />
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 5, mb: 10 }}>          
          <LineChart Data={chartTelemetryData('temperature')} Title={'Temperature'} border_color={'rgb(255, 99, 132)'} background_color= {'rgba(255, 99, 132, 0.5)'} />
          <LineChart Data={chartTelemetryData('humidity')} Title={'Humidity'} border_color={'rgb(10, 255, 125)'} background_color= {'rgba(10, 255, 125, 0.5)'} />
          <LineChart Data={chartTelemetryData('weight')} Title={'Weight'} border_color={'rgb(132, 99, 255)'} background_color= {'rgba(132, 99, 255, 0.5)'} />
          <BarChart Data={chartTelemetryData('deltaweight')} Title={'Delta Weight'} border_color={'rgb(255, 85, 0)'} background_color= {'rgba(255, 85, 0, 0.5)'} />
        </Box>
      </Paper>
    </Container>
  );
}