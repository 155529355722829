import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box, SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdminRole, AdminType } from '../../types/admins.type';

interface AddAdminModalProps {
  open: boolean;
  onClose: () => void;
  onAddAdmin: (admin: AdminType) => void;
}

const AddAdminModal: React.FC<AddAdminModalProps> = ({ open, onClose, onAddAdmin }) => {
  const { t } = useTranslation();
  const [newAdmin, setNewAdmin] = useState<Partial<AdminType>>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    roles: [],
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  const handleRolesChange = (event: SelectChangeEvent<typeof newAdmin.roles>) => {
    const { value } = event.target;
    setNewAdmin({
      ...newAdmin,
      roles: (typeof value === 'string' ? value.split(',') : value) as AdminRole[],
    });
  };

  const handleSubmit = () => {
    onAddAdmin(newAdmin as AdminType);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('adminsPage.addAdminTitle')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="firstname"
          label={t('adminsPage.firstname')}
          type="text"
          fullWidth
          value={newAdmin.firstname}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="lastname"
          label={t('adminsPage.lastname')}
          type="text"
          fullWidth
          value={newAdmin.lastname}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="email"
          label={t('adminsPage.email')}
          type="email"
          fullWidth
          value={newAdmin.email}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="password"
          label={t('adminsPage.password')}
          type="password"
          fullWidth
          value={newAdmin.password}
          onChange={handleInputChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>{t('adminsPage.roles')}</InputLabel>
          <Select
            multiple
            value={newAdmin.roles || []}
            onChange={handleRolesChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as AdminRole[]).map((value) => (
                  <Chip key={value} label={value}/>
                ))}
              </Box>
            )}
          >
            {Object.values(AdminRole).map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{  backgroundColor: 'gray' }}
        >
          {t('popupDialog.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          style={{  backgroundColor: 'green' }}
        >
          {t('popupDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAdminModal;
