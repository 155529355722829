import i18n from 'translations/i18n';

export const getOrganisationTableHead = () => [
  {
    id: 'companyName',
    label: i18n.t('tableHeaders.company'),
    alignRight: false,
  },
  {
    id: 'contactEmail',
    label: i18n.t('tableHeaders.contactEmail'),
    alignRight: false,
  },
  {
    id: 'emailHandler',
    label: i18n.t('tableHeaders.emailHandler'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'groups', label: i18n.t('tableHeaders.groups'), alignRight: false },
  { id: 'address', label: i18n.t('tableHeaders.address'), alignRight: false },
  {
    id: 'isOpen',
    label: i18n.t('organisationsPage.isOpen'),
    alignRight: false,
    numeric: false,
    boolean: true,
  },
  { id: '' },
];

export const getAdminsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'email', label: i18n.t('tableHeaders.email'), alignRight: false },
  { id: 'roles', label: i18n.t('tableHeaders.roles'), alignRight: false },
  { id: '' },
];

export const getUsersTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'email', label: i18n.t('tableHeaders.email'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getCompobotsTableHead = () => [
  { id: 'deviceId', label: i18n.t('tableHeaders.deviceId'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getGroupsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'qrcode', label: i18n.t('tableHeaders.qrCode'), alignRight: false },
  { id: '' },
];

export const getAllGroupsTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: 'qrcode', label: i18n.t('tableHeaders.qrCode'), alignRight: false },
  { id: '' },
];

export const getNotificationsTableHead = () => [
  { id: 'title', label: i18n.t('tableHeaders.title'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },
  { id: 'type', label: i18n.t('tableHeaders.type'), alignRight: false },
  {
    id: 'timestamp',
    label: i18n.t('tableHeaders.scheduledAt'),
    alignRight: false,
  },
  {
    id: 'delivered',
    label: i18n.t('tableHeaders.delivered'),
    alignRight: false,
  },
  {
    id: 'targetType',
    label: i18n.t('tableHeaders.targetType'),
    alignRight: false,
  },
  {
    id: 'numberOfTargets',
    label: i18n.t('tableHeaders.numberOfTargets'),
    alignRight: false,
  },
  { id: '' },
];

export const getChallengeTableHead = () => [
  {
    id: 'challengeName',
    label: i18n.t('tableHeaders.challengeName'),
    alignRight: false,
  },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  {
    id: 'startDate',
    label: i18n.t('tableHeaders.startDate'),
    alignRight: false,
  },
  {
    id: 'endDate',
    label: i18n.t('tableHeaders.endDate'),
    alignRight: false,
  },
  {
    id: 'currenctScore',
    label: i18n.t('tableHeaders.currentScore'),
    alignRight: false,
  },
  {
    id: 'targetScore',
    label: i18n.t('tableHeaders.targetScore'),
    alignRight: false,
  },
  { id: '' },
];

export const getQuizTableHead = () => [
  {
    id: 'quizName',
    label: i18n.t('tableHeaders.quizName'),
    alignRight: false,
  },
  {
    id: 'challengeName',
    label: i18n.t('tableHeaders.challengeName'),
    alignRight: false,
  },
  {
    id: 'scoreForCorrect',
    label: i18n.t('tableHeaders.scoreForCorrect'),
    alignRight: false,
  },
  { id: '' },
];
