import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ErrorText from 'components/ErrorText/ErrorText';
import Img from 'components/Img/Img';
import SnackbarContext from 'contexts/snackbarContext';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { login } from 'services/auth.service';
import { CustomForm, FormHeader, ImageWrapper } from './LoginForm.style';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

type FormFields = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { handleOpen } = useContext(SnackbarContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onSubmit = (user: FormFields) => {
    login(user)
      .then(({ data }) => {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('roles', JSON.stringify(data.roles));
        navigate('/users');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  return (
    <CustomForm onSubmit={handleSubmit(onSubmit)}>
      <LanguageSwitcher isLogin />
      <FormHeader>
        <Stack>
          <Typography variant="h4" gutterBottom>
            {t('login.title')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }} gutterBottom>
            {t('login.subtitle')}
          </Typography>
        </Stack>
        <ImageWrapper>
          <Img src={'compocity-logo.png'}></Img>
        </ImageWrapper>
      </FormHeader>
      <Stack spacing={4}>
        <TextField
          error={errors.email ? true : false}
          fullWidth
          autoComplete="email"
          type="email"
          label={t('formFields.email')}
          {...register('email', { required: true })}
        />
        {errors.email && (
          <ErrorText>{t('formErrors.emailRequired')}</ErrorText>
        )}

        <TextField
          error={errors.email ? true : false}
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          {...register('password', { required: true })}
          label={t('formFields.password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errors.password && (
          <ErrorText>{t('formErrors.passwordRequired')}</ErrorText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={false}
        >
          {t('login.loginButton')}
        </LoadingButton>
      </Stack>
    </CustomForm>
  );
}
