import React from 'react';

interface TileProps {
  label: string;
  value: number[];
}
//  {
const Tile : React.FC<TileProps> = ({ label, value }) =>{
  const tileStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    width: '110px',
    height: '110px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    margin: '10px',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  };

  const labelStyle = {
    fontSize: '15px',
    color: '#555',
    marginTop: '-5px',
    marginBottom: '30px',
  };

  const valueStyle = {
    fontSize: '24px',
    fontWeight: 'bold' as const,
    color: '#333',
    marginBottom: '-10px',
  };

  return (
    <div style={tileStyle}>
      <div style={labelStyle}>{label}</div>
      <div style={valueStyle}>{value[0]}</div>
    </div>
  );
};

export default Tile;