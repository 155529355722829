import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { CompobotTelemetryType } from 'types/compobots.type';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

interface LineChartProps {
  Data: CompobotTelemetryType[];
  Title: string;
  border_color: string;
  background_color: string;
}

//TODO: nagyítás

const LineChart: React.FC<LineChartProps> = ({ Data, Title, border_color,  background_color}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: Title,
        font: {
          size: 24,
        }
      },

      zoom: {
        pan: {
          enabled: true,
          mode: 'xy' as const, // Enable panning on x-axis
        },
        zoom: {
          enabled: true,
          mode: 'x' as const, // Enable zooming on x-axis
          wheel: {
            enabled: true, // Enable zooming with mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming by pinching on touch devices
          },
          drag: {
            enabled: false, // Enable zooming by dragging
          },
        },
      },
    },
  };

  const data = {
    labels: Data.map(item => new Date(item.createdAt).toLocaleString()),
    datasets: [
      {
        label: Title,
        data: Data.map(item => item.value),
        borderColor: border_color,
        backgroundColor: background_color,
        borderWidth: 5,
      },
    ],
  };

  return <Line data={data} options={options} />;
};

export default LineChart;