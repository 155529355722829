import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getAdminsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {
  handleClick,
  handleSelectAllClick,
  sortByColumn,
} from 'services/list.service';
import {
  getAdmins,
  deleteAdmin,
  deleteAdminBulk,
  searchAdmins, addAdmin,
} from 'services/admins.service';
import { AdminType } from '../../types/admins.type';
import AddAdminModal from '../../components/Popup/AddAdminModal';

export default function AdminsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [adminList, setAdminList] = useState<AdminType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    getAdminsHandler();
  }, []);

  const getAdminsHandler = () => {
    getAdmins().then((response) => {
      setAdminList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchAdmins(filterName).then((response) => {
          setAdminList(response.data);
        });
      } else {
        getAdminsHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteAdmin = (userId: string) => {
    deleteAdmin(userId)
      .then(() => {
        setAdminList([...adminList].filter(({ id }) => id !== userId));
        handleSelectAllClick(false, adminList);
        setOpenModal(false);
        handleOpen(t('adminsPage.adminDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteAdminBulk = (userIds: string[]) => {
    deleteAdminBulk(userIds)
      .then(() => {
        setAdminList(adminList.filter(({ id }) => !userIds.includes(id)));
        handleSelectAllClick(false, adminList);
        setOpenModal(false);
        handleOpen(t('adminsPage.adminsDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteAdminsAction = () => {
    if (selected.length > 1) {
      handleDeleteAdminBulk(selected);
    } else {
      handleDeleteAdmin(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const handleAddAdmin = (newAdmin: AdminType) => {
    addAdmin(newAdmin)
      .then((response) => {
        setAdminList([...adminList, response.data]);
        setOpenAddModal(false);
        handleOpen(t('adminsPage.adminAddedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const sortedAdminsList = sortByColumn(
    adminList,
    orderBy as keyof AdminType,
    order,
  );

  const adminsTableHead = useMemo(getAdminsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('adminsPage.title')}
        buttonTitle={t('adminsPage.addAdminTitle').toString()}
        hasButton={true}
        onButtonClick={() => setOpenAddModal(true)}
      />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={adminsTableHead}
                rowCount={adminList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, adminList))
                }
              />
            }
            <TableBody>
              {sortedAdminsList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    firstname,
                    lastname,
                    email,
                    roles,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => setSelected(handleClick(id, selected))}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {`${firstname} ${lastname}`}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">
                        {roles.join(', ')}
                      </TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          hideEdit={true}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!adminList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound/>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={adminList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteAdminsAction}
        handleClose={() => setOpenModal(false)}
      />
      <AddAdminModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        onAddAdmin={handleAddAdmin}
      />
    </Container>
  );
}