import { useState, useEffect } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompobotKernelLogs, getCompobots } from '../../services/compobot.service';
import { CompobotKernelLogType, CompobotType } from '../../types/compobots.type';

export default function CompoBotLogsPage() {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<CompobotKernelLogType[]>([]);
  const [compobots, setCompobots] = useState<CompobotType[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [logLevel, setLogLevel] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCompobots().then((r) => setCompobots(r.data));
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get('deviceId') || '';
    const logLevelParam = searchParams.get('logLevel') || '';
    const fromDateParam = searchParams.get('fromDate') || '';
    setSelectedDeviceId(deviceIdParam);
    setLogLevel(logLevelParam);
    setFromDate(fromDateParam);
    handleFetchLogs(deviceIdParam, fromDateParam, logLevelParam);
  }, [location.search]);

  const handleFetchLogs = async (deviceId?: string, fromDate?: string, logLevel?: string) => {
    try {
      const fetchedLogs = await getCompobotKernelLogs(deviceId, fromDate, logLevel);
      setLogs(fetchedLogs.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const updateSearchParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    navigate({ search: searchParams.toString() });
  };

  const clearFilters = () => {
    setSelectedDeviceId('');
    setFromDate('');
    setLogLevel('');
    updateSearchParams({ deviceId: '', fromDate: '', logLevel: '' });
    handleFetchLogs();
  };

  const getRowBackgroundColor = (logLevel: string) => {
    switch (logLevel) {
    case 'DEBUG':
      return 'rgba(224,224,224,1)';
    case 'INFO':
      return 'rgba(255,255,255,1)';
    case 'WARN':
      return 'rgba(255,167,38,0.5)';
    case 'ERROR':
      return 'rgba(239,83,80,0.5)';
    default:
      return 'inherit';
    }
  };

  return (
    <Container>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="a"
          onClick={() => navigate('/compobots')}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t('compobotLogsPage.compobotList')}
        </Typography>
        <Typography variant="h4" component="span">
          {'>'}
        </Typography>
        <Typography variant="h4" component="span">
          {t('compobotLogsPage.logs')}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="compobot-select-label">{t('compobotLogsPage.deviceId')}</InputLabel>
          <Select
            labelId="compobot-select-label"
            value={selectedDeviceId || 'all'}
            label={t('compobotLogsPage.deviceId')}
            onChange={(e) => {
              const value = e.target.value === 'all' ? '' : e.target.value;
              setSelectedDeviceId(value);
              updateSearchParams({ deviceId: value });
              handleFetchLogs(value, fromDate, logLevel);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {compobots.map((compobot) => (
              <MenuItem key={compobot.deviceId} value={compobot.deviceId}>
                {compobot.deviceId} - {compobot.organisation?.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t('compobotLogsPage.fromDate')}
          type="date"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value);
            updateSearchParams({ fromDate: e.target.value });
            handleFetchLogs(selectedDeviceId, e.target.value, logLevel);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="log-level-select-label">{t('compobotLogsPage.logLevel')}</InputLabel>
          <Select
            labelId="log-level-select-label"
            value={logLevel || 'all'}
            label={t('compobotLogsPage.logLevel')}
            onChange={(e) => {
              const value = e.target.value === 'all' ? '' : e.target.value;
              setLogLevel(value);
              updateSearchParams({ logLevel: value });
              handleFetchLogs(selectedDeviceId, fromDate, value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="DEBUG">Debug</MenuItem>
            <MenuItem value="INFO">Info</MenuItem>
            <MenuItem value="WARN">Warn</MenuItem>
            <MenuItem value="ERROR">Error</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={clearFilters}>
          {t('compobotLogsPage.clearFilters')}
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('compobotLogsPage.deviceId')}</TableCell>
              <TableCell>{t('compobotLogsPage.key')}</TableCell>
              <TableCell>{t('compobotLogsPage.value')}</TableCell>
              <TableCell>{t('compobotLogsPage.additional')}</TableCell>
              <TableCell>{t('compobotLogsPage.logLevel')}</TableCell>
              <TableCell>{t('compobotLogsPage.createdAt')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow key={log.uid} sx={{ backgroundColor: getRowBackgroundColor(log.logLevel) }}>
                <TableCell>{log.compobot.deviceId}</TableCell>
                <TableCell>{log.key}</TableCell>
                <TableCell>{log.value}</TableCell>
                <TableCell>{log.additional}</TableCell>
                <TableCell>{log.logLevel}</TableCell>
                <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}