import { ApiClient } from './interceptor';
import { AdminType } from '../types/admins.type';

const api = ApiClient.getInstance();

export const getAdmins = async () => {
  return await api.get('admin/admins');
};

export const searchAdmins = async (searchTerm: string) => {
  return await api.get(`admin/admins/lookup/${searchTerm}`);
};

export const addAdmin = async (admin: AdminType) => {
  return await api.post('admin/admins', admin);
};

// TODO: use it to update roles
export const updateAdmin = async (id: string, admin: AdminType) => {
  return await api.put(`admin/admins/${id}`, admin);
};

export const deleteAdmin = async (userId: string) => {
  return await api.delete(`admin/admins/${userId}`);
};

export const deleteAdminBulk = async (userIds: string[]) => {
  return await api.deleteWithParams('admin/admins', { UIds: userIds });
};