import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { CompobotTelemetryType } from 'types/compobots.type';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface BarChartProps {
  Data: CompobotTelemetryType[];
  Title: string;
  border_color: string;
  background_color: string;
}

const BarChart: React.FC<BarChartProps> = ({ Data, Title, border_color,  background_color}) => {

  const data = {
    labels: Data.map(item => new Date(item.createdAt).toLocaleString()),
    datasets: [
      {
        label: 'Delta Weight',
        data: Data.map(item => item.value),
        borderColor: border_color,
        backgroundColor: background_color,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: Title,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;