import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

type PageHeaderTypes = {
  title: string;
  buttonTitle?: string;
  hasButton: boolean;
  onButtonClick?: () => void;
  hasBackButton?: boolean;
};

export default function PageHeader({
  title,
  buttonTitle,
  hasButton,
  onButtonClick,
  hasBackButton,
}: PageHeaderTypes) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ paddingBottom: '20px' }}
    >
      <Grid item>
        {hasBackButton && (
          <Link onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIcon style={{ height: '22px' }}/>
              {t('general.backButtonLabel')}
            </Typography>
          </Link>
        )}

        <Typography variant="h4" component="h1" sx={{ paddingBottom: '15px' }}>
          {title}
        </Typography>
      </Grid>
      {hasButton && !onButtonClick && (
        <Grid item>
          <Button
            variant="contained"
            component={RouterLink}
            to={'new'}
            startIcon={<AddIcon/>}
          >
            {buttonTitle || ''}
          </Button>
        </Grid>
      )}
      {hasButton && onButtonClick && (
        <Grid item>
          <Button
            variant="contained"
            onClick={onButtonClick}
            startIcon={<AddIcon/>}
          >
            {buttonTitle || ''}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}